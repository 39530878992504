.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

:root {
	--pink: #e70290;
	--pink-dark: #d00280;
	--orange: #ff8d1c;
	--orange-dark: #ca6a0a;
	--blue: #0292e5;
	--blue-dark: #0283c9;
	--white: #fefefe;
	--light-gray: #eeeeee;
}

button {
	border-radius: 20px !important;
}
button.pink,
button.orange,
button.blue {
	color: white;
}
button.pink {
	background-color: var(--pink) !important;
}
button.pink:hover {
	background-color: var(--pink-dark) !important;
}
button.orange {
	background-color: var(--orange) !important;
}
button.orange:hover {
	background-color: var(--orange-dark) !important;
}
button.blue {
	background-color: var(--blue) !important;
}
button.blue:hover {
	background-color: var(--blue-dark) !important;
}
button.white {
	border: 1px solid #999999;
	background-color: var(--white) !important;
}
button.white:hover {
	background-color: var(--light-gray) !important;
}

.spaced-children.vertical > :not(:last-child) {
	margin-bottom: 8px;
}
.spaced-children.horizonal {
	display: flex;
	align-items: center;
}
.spaced-children.horizonal > :not(:last-child) {
	margin-right: 8px;
}

.top-genres {
	color: var(--orange-dark) !important;
	background-color: white !important;
}
.top-genres:hover {
	color: white !important;
	background-color: var(--pink) !important;
}
